













































































































































































































































import { SfOverlay, SfButton } from "@storefront-ui/vue";

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import { useTopBar } from "./TopBar/useTopBar";
import { Icon } from "@iconify/vue2";
import BackDrop from "./General/BackDrop.vue";
import useStoryblok from "~/composables/useStoryblok";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
const MobileCategorySidebar = () =>
  import(
    "~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue"
  );

import { useConfigStore } from "~/stores/config";

export default defineComponent({
  components: {
    HeaderLogo,
    SfButton,
    BackDrop,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),

    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),

    Icon,
    CartModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/components/CartModal.vue"
      ),
    SfOverlay,
    MobileCategorySidebar,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
      isCartSidebarOpen,
    } = useUiState();

    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart, load: loadCart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();

    const configStore = useConfigStore();
    const menColor = computed(() => {
      return configStore.menColor;
    });
    const currentLogo = computed(() => {
      if (menColor.value) {
        return "/icons/logo-reduced.svg";
      } else {
        return "/icons/Trinity_logo.png";
      }
    });

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    let itIsDesktop = ref(false);
    if (app.$device.isDesktop) {
      itIsDesktop = ref(true);
    }
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );
    const activeNav = ref(-1);
    const currentCategory = ref<CategoryTree | null>(null);
    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    const hasChildren = (category: CategoryTree) =>
      Boolean(category?.children?.length);

    const onMouseEnter = (category: CategoryTree, index) => {
      if (index === 0) {
        activeNav.value = 0;
      } else {
        activeNav.value = index;
      }
      currentCategory.value = category;
    };

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: "customer-my-profile" }));
      } else {
        toggleLoginModal();
      }
    };
    const { getStoryblokData } = useStoryblok();
    const storyblokData = ref(null);

    const setNoScrollOnBody = (noScroll, type) => {
      if (noScroll) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
      if (type === "mob-nav") {
        toggleMobileMenu();
      }
      if (type === "cart") {
        toggleCartSidebar();
      }
    };
    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      setNoScrollOnBody(true, "mob-nav");
    };
    const closeMenuAndNavigate = () => {
      activeNav.value = -1;
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );
      await getStoryblokData("mainmenu").then((res) => {
        storyblokData.value = res;
      });

      await loadCart();
    });

    onMounted(async () => {
      await loadCartTotalQty();
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      onMouseEnter,
      isCartSidebarOpen,
      activeNav,
      storyblokData,
      itIsDesktop,
      setNoScrollOnBody,
      loadCategoryMenu,
      isMobileMenuOpen,
      menColor,
      currentLogo,
      closeMenuAndNavigate,
    };
  },
});
